import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Spin } from 'antd';

import './App.css';
import { withFirebase } from './firebase';
import { AuthUserContext } from './session';

const ViewMenu = React.lazy(() => import('./containers/ViewMenu'));
const ViewTable = React.lazy(() => import('./containers/ViewTable'));
const ViewGroup = React.lazy(() => import('./containers/ViewGroup'));
const AppLayout = React.lazy(() => import('./containers/AppLayout'));

function App({ firebase }) {
  const [authUser, setAuthUser] = useState(JSON.parse(localStorage.getItem('authUser'))) // fetch authUser from localStorage cache

  const loading = () => {
    return <div style={{ height: '100vh', display: 'flex' }}>
      <Spin size="large" style={{margin: 'auto'}} />
    </div>
  }

  useEffect(() => {
    // fetch authUser from firestore
    const unsubscribe = firebase.onAuthUserListener(authUser => {
      setAuthUser(authUser)
      localStorage.setItem('authUser', JSON.stringify(authUser)); // cache authUser to localStorage
    }, () => {
      setAuthUser(null)
      localStorage.removeItem('authUser'); // remove authUser from localStorage cache
    });
    return () => unsubscribe() // call unsubscribe function returned by onAuthUserListener on clean up
  }, []);

  return (
    <AuthUserContext.Provider value={authUser}>
      <BrowserRouter>
        <Suspense fallback={loading()}>
          <Switch> 

            {/* View Menu does not use a layout (fullscreen) */}
            <Route
              path='/o/:orgId/menus/:menuId'
              render={(props) => <ViewMenu {...props} />}
              exact={true}
            />

            {/* View Table does not use a layout (fullscreen) */}
            <Route
              path='/o/:orgId/tables/:tableId'
              render={(props) => <ViewTable {...props} />}
              exact={true}
            />

						{/* View Group does not use a layout (fullscreen) */}
						<Route
              path='/o/:orgId/groups/:groupId'
              render={(props) => <ViewGroup {...props} />}
              exact={true}
            />

            {/* Everything else uses an Application layout (Header Sidebar Footer) */}
            <Route 
              path='/o/:orgId'
              render={(props) => <AppLayout {...props} />}
            />
            <Route 
              path='/'
              render={(props) => <AppLayout {...props} />}
            />

          </Switch>
        </Suspense>
      </BrowserRouter>
    </AuthUserContext.Provider>
  );
}

export default withFirebase(App);
